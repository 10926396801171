const yup = require('yup')
const { requiredFields, labelify } = require('./helpers')

const shape = labelify({
  resort: yup.string(),
  name: yup.string().ensure(),
  current: yup.number().default(0),
  maximum: yup.number().min(1).default(1),
  format: yup.string().oneOf(['fraction', 'percent', 'relative']).ensure().default('fraction'),
  notes: yup.string().max(100).meta({ maxLength: 100 }).ensure(),
  closed: yup.boolean().default(false),
  disabled: yup.boolean().default(false),
  manageInStaffApp: yup.boolean().default(false),
})

const capacityLocationSchemaPartial = yup.object().shape(shape)
const capacityLocationSchema = capacityLocationSchemaPartial.shape(requiredFields(['name', 'type', 'maxiumum'])(shape))

module.exports = { capacityLocationSchema, capacityLocationSchemaPartial }

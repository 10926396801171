import { connect } from '@cerebral/react'
import { Box, Button, Typography } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import ImageIcon from '@mui/icons-material/Image'
import { sequences } from 'cerebral'
import cc from 'classcat'
import PropTypes from 'prop-types'
import { compose } from 'ramda'
import React, { useCallback, useEffect, useState } from 'react'
import Dropzone from 'react-dropzone'
import sizeMe from 'react-sizeme'
import Image from '../elements/Image'
import StatusText from '../elements/StatusText'
import { GetApp, Delete } from '@mui/icons-material'
import { useFormikContext } from 'formik'

const cloudName = import.meta.env.VITE_CLOUDINARY_CLOUD_NAME
const uploadPreset = import.meta.env.VITE_CLOUDINARY_UPLOAD_PRESET

const addDownloadToUrl = (url) => {
  const parsedUrl = new URL(url)
  let pathParts = parsedUrl.pathname.split('/')

  // Find the upload part which is the reference point
  const uploadIndex = pathParts.findIndex((part) => part === 'upload')
  if (pathParts.some((part) => part === 'fl_attachment')) {
    return url
  }

  pathParts.splice(uploadIndex + 1, 0, 'fl_attachment')
  parsedUrl.pathname = pathParts.join('/')
  return parsedUrl.toString()
}

const styles = (theme) => ({
  DropAccept: {},
  DropContainer: {
    backgroundColor: theme.palette.grey[50],
    border: `1px dashed ${theme.palette.grey[400]}`,
    borderRadius: theme.spacing(1),
    '$DropAccept &': {
      border: '1px solid #003B77',
      backgroundColor: '#F2F6F9',
    },
  },
  DropContainerUploading: {
    borderStyle: 'solid',
  },
  DropContainerWithImage: {
    borderColor: 'transparent',
  },
  Image: {
    position: 'absolute',
    width: '100%',
    borderRadius: theme.spacing(1),
    overflow: 'hidden',
  },
  Overlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  Placeholder: {
    maxWidth: '11em',
    margin: '2em 0',
  },
})

const DropUpload = ({
  classes,
  onChange,
  signUpload,
  ratio = 0.8625,
  image,
  optional,
  crop = 'pad',
  size: { width },
  buttonControls: { showRemove = true, showDownload = true } = {},
  error,
  type,
  multiple = false,
  name,
}) => {
  const { touched, submitCount, errors } = useFormikContext()
  const [errorMessage, setErrorMessage] = useState('')
  const [uploading, setUploading] = useState(false)

  type = (image && image.resource_type) || type || 'image'
  const hasImage = image && image.public_id
  const typeName = type === 'video' ? 'video' : 'photo'
  const height = Math.ceil(width * ratio)

  const onDrop = useCallback(
    async (files) => {
      if (!multiple) {
        files = files.slice(0, 1)
      }

      if (files.length > 0) {
        setUploading(true)
        const images = []
        try {
          for (const file of files) {
            type = file.type.split('/')[0] || type
            // Here we're handling signed uploads directly to cloudinary.
            const signed = await signUpload({ upload_preset: uploadPreset })
            const formData = new FormData()
            formData.set('file', file)
            formData.append('upload_preset', uploadPreset)
            Object.keys(signed).forEach((key) => {
              formData.set(key, signed[key])
            })
            const response = await fetch(`https://api.cloudinary.com/v1_1/${cloudName}/${type}/upload`, {
              method: 'POST',
              body: formData,
            })
            const image = response ? await response.json() : null
            images.push(image)
          }
          setUploading(false)
          onChange(multiple ? images : images[0])
        } catch {
          setErrorMessage('Sorry! There was a problem uploading that file.')
          setUploading(false)
        }
      }
    },
    [onChange, signUpload, type]
  )

  // Turn a string image into a request to create a cloudinary image.
  useEffect(() => {
    if (image && typeof image === 'string') {
      const fetchImage = async () => {
        try {
          const response = await fetch(image)
          if (!response.ok) {
            throw new Error(`Could not download ${image}`)
          }
          const blob = await response.blob()
          onDrop([blob])
        } catch {
          // Ignore these errors.
        }
      }
      fetchImage()
    }
  }, [image])

  return (
    <React.Fragment>
      <Dropzone style={{ width: '100%' }} activeClassName={classes.DropActive} acceptClassName={classes.DropAccept} onDrop={onDrop}>
        <div
          style={{ paddingBottom: `${ratio * 100}%` }}
          className={cc([classes.DropContainer, uploading && classes.DropContainerUploading, hasImage && classes.DropContainerWithImage])}
        >
          <div className={classes.Overlay}>
            {hasImage ? (
              <Image alt="Uploaded file" className={classes.Image} image={image} options={{ width, height, crop }} />
            ) : (
              <Typography classes={{ root: classes.Placeholder }} variant="body1" component="div">
                <ImageIcon />
                {uploading ? (
                  <Typography>Uploading...</Typography>
                ) : (
                  <React.Fragment>
                    <Typography>Drag & drop or select file{multiple ? 's' : ''}</Typography>
                    {optional && <Typography>(optional)</Typography>}
                  </React.Fragment>
                )}
              </Typography>
            )}
          </div>
        </div>
      </Dropzone>

      {Boolean(errorMessage || error) && (
        <Box>
          <StatusText variant="caption" color="error">
            {errorMessage || error}
          </StatusText>
        </Box>
      )}

      {hasImage && (showRemove || showDownload) ? (
        <Box mt={2} display="flex" alignItems="center">
          {showRemove && (
            <Button
              startIcon={<Delete />}
              variant="outlined"
              color="primary"
              classes={{ root: classes.Button }}
              onClick={(e) => {
                e.stopPropagation()
                onChange(null)
              }}
            >
              Remove {typeName}
            </Button>
          )}

          <Box mr={2} />

          {showDownload && (
            <Button color="primary" variant="outlined" startIcon={<GetApp />} href={addDownloadToUrl(image?.secure_url)}>
              Download
            </Button>
          )}
        </Box>
      ) : null}

      {(touched?.[name] || submitCount > 0) && errors?.[name] && (
        <Box m={1}>
          <StatusText color="error">Photo or video is required.</StatusText>
        </Box>
      )}
    </React.Fragment>
  )
}

DropUpload.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  signUpload: PropTypes.func,
  classes: PropTypes.any,
  onChange: PropTypes.func,
  ratio: PropTypes.number,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  optional: PropTypes.bool,
  crop: PropTypes.string,
  size: PropTypes.object,
  buttonControls: PropTypes.object,
  multiple: PropTypes.bool,
  error: PropTypes.string,
}

const hocs = compose(withStyles(styles), sizeMe({ refreshRate: 500, refreshMode: 'debounce' }))

export default connect({ signUpload: sequences`uploads.signUpload` }, hocs(DropUpload))
